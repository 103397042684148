import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";
import logo from "../../../Assests/Logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import patnear from "../../../Assests/Patener.png";

const Footer = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/terms-and-conditions");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickPrivacy = () => {
    history.push("/privacy-policy");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickAbout = () => {
    history.push("/about");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickService = () => {
    history.push("/Services");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickProject = () => {
    history.push("/Projects");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickGallary = () => {
    history.push("/Gallary");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickContactus = () => {
    history.push("/contact");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="containernew">
        <section className="newletter">
          <div className="container flexSB Partner">
            <div>
              <h1>Associate Partner</h1>
            </div>
            <div>
              <img src={patnear} width={300} />
            </div>
          </div>
        </section>
        <footer>
          <div className="container padding">
            <div className="box logo1">
              {/* <h1>ACADEMIA</h1>
            <span>ONLINE EDUCATION & LEARNING</span> */}
              <img loading="lazy" src={logo} width={260}></img>
              <p>Your Land, Our Expertise: Mapping Success Together.</p>

              {/* <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i> */}
            </div>
            <div className="box link">
              <h3>Explore</h3>
              <ul>
                <li onClick={handleClickAbout} style={{ cursor: "pointer" }}>
                  About Us
                </li>

                <li onClick={handleClickService} style={{ cursor: "pointer" }}>
                  Services
                </li>

                <li onClick={handleClickProject} style={{ cursor: "pointer" }}>
                  our Projects
                </li>

                <li onClick={handleClickGallary} style={{ cursor: "pointer" }}>
                  Gallary
                </li>

                <li
                  onClick={handleClickContactus}
                  style={{ cursor: "pointer" }}
                >
                  Contact us
                </li>
              </ul>
            </div>
            <div className="box link">
              <h3>Quick Links</h3>
              <ul>
                <li
                  onClick={handleClickContactus}
                  style={{ cursor: "pointer" }}
                >
                  Contact us
                </li>

                <li onClick={handleClickProject} style={{ cursor: "pointer" }}>
                  our Projects
                </li>

                <li onClick={handleClick} style={{ cursor: "pointer" }}>
                  Terms & Conditions
                </li>
                {/* <li onClick={handleClick}>Terms & Conditions</li> */}
                <li onClick={handleClickPrivacy} style={{ cursor: "pointer" }}>
                  Privacy Policy
                </li>

                {/* <li>Feedbacks</li> */}
              </ul>
            </div>
            <div className="box blogfooter">
              <h3>Recent Post</h3>
              {blog.slice(0, 3).map((val) => (
                <div className="items flexSB footer_blog">
                  <div className="img">
                    <img loading="lazy" src={val.cover} alt="" />
                  </div>
                  <div className="text">
                    <span>
                      <i className="fa fa-calendar-alt"></i>
                      <label htmlFor="">{val.date}</label>
                    </span>
                    <span>
                      <i className="fa fa-user"></i>
                      <label htmlFor="">{val.type}</label>
                    </span>
                    <h4>{val.title.slice(0, 40)}...</h4>
                  </div>
                </div>
              ))}
            </div>
            <div className="box last">
              <h3>Have a Questions?</h3>
              <ul>
                <li>
                  <i className="fa fa-map"></i>
                  Roshan Manzil, At/Post: Vetalnagar, Lingali Road, Taluka:
                  Daund, Dist: Pune – 413801.
                </li>
                <li>
                  <i className="fa fa-map"></i>
                  Branch Office- <br></br>
                  Shop no, 37 1st Floor, Sri Gururaj Seva Vaishnavi, Raghavendra
                  Colony, KEB Road,Karnataka Bidar-585401 <br></br> Contact No:
                  +917259248801
                </li>
                <li>
                  <i className="fa fa-phone-alt"></i>
                  +918552008921,+919975765600, +919766674386, +919762979448
                </li>
                <li>
                  <i className="fa fa-paper-plane"></i>
                  sraoffice.daund@gmail.com
                </li>
              </ul>
            </div>
          </div>
        </footer>
        <div className="legal">
          <p>
          ©All Rights Reserved. SR Associates Land Surveyors 2024 | Designed & Marketed by {" "}

            <i className="fa fa-heart"></i> by <a style={{color:"black ", fontWeight:"bold"}} href="mailto:mandardhawale96@gmail.com" >PixelTech  </a>
          </p>
        </div>
      </div>
    </>

  );
};

export default Footer;
