import React from 'react'
import Heading from '../common/heading/Heading'
import { project } from "../../dummydata"


import "./project.css"
const Project = () => {
  return (
    <>
      <section className='testimonal padding'>
        <div className='container'>
          <Heading subtitle='SR Associates' title='Projects' />

          <div className='content grid2' id='project_card'>
            {project.map((val) => (
              <div className='items shadow'>
                <div className='box flex'>
                  {/* <div className='img'>
                    <img src={val.cover} alt='' />
                    <i className='fa fa-quote-left icon'></i>
                  </div> */}
                  <div className='name'>
                    <div><h2>{val.name}</h2>
                    <h4 className='place'>{val.place}</h4>
                    </div>
                    
                    <span>{val.post}</span>
                  </div>
                </div>
                <p>{val.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Project