import React from "react"
import Back from "../common/back/Back"
import PriceCard from "./PriceCard"
import "./price.css"
import Faq from "./Faq"
import Testimonal from "../home/testimonal/Testimonal"
import Project from "../project/Project"

const Pricing = () => {
  return (
    <div className="project_margin">
      <Back title='Our Projects' />
      {/* <Testimonal/> */}
      <Project/>
      <Faq />
    </div>
  )
}

export default Pricing
