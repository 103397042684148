import React from "react";
import "./about.css";
import Back from "../common/back/Back";
import AboutCard from "./AboutCard";
import Heading from "../common/heading/Heading";
import { homeAbout } from "../../dummydata";
import img1 from "../../Assests/About1.jpg";
import infra from "../../Assests/infraa.jpg";
import team from "../../Assests/team.png";
import quality from "../../Assests/quality.png";
import whywe from "../../Assests/whywe.jpg";

import Responsive_About from "./responsive_About/Responsive_About";


const About = () => {
  return (
    <div className="about_margin">
      <Back title="About Us" />
      <section className="aboutHome">
        {/* first box */}

        <div className="abouthidden">
          <div className="container flexSB aboutheight">
            <div className="right row rightheight">
              <Heading
                subtitle=""
                title="SR ASSOCIATES LAND SURVEYORS – Prime Experts in Land Survey of Land Survey"
              />
              <div className="items">
                <div className="item flexSB">
                  <div className="text">
                    <p>
                      SR Associates Land Surveyors is a leading service provider
                      in the domain of land surveying since 12 years. Our rich
                      expertise and experience enables us to deliver competent
                      consultancy services across real estate, construction and
                      corporate sectors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="leftt row rightheight">
              <img src={img1} alt="" />
            </div>
          </div>

          {/* second box */}
          <div className="container flexSB aboutheight">
            <div className="right row rightheight">
              <Heading subtitle="" title="Our Team" />
              <div className="items">
                <div className="item flexSB">
                  <div className="text">
                    <p>
                      SR Associates Land Surveyors has a strong team of
                      consultants, engineers, surveyors, analysts and marketing
                      executives. The team is extremely reliable and capable of
                      efficiently accomplishing the projects under a given
                      deadline. We ensure our team undergoes through training
                      programs at regular intervals to keep them updated about
                      recent technological advancements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="leftt row rightheight">
              <img src={team} alt="" />
            </div>
          </div>

          {/* THIRD box */}

          <div className="container flexSB aboutheight ">
            <div className="right row rightheight">
              <Heading subtitle="" title="Infrastructure" />
              <div className="items">
                <div className="item flexSB">
                  <div className="text">
                    <p>
                      Our inspection procedures and survey operations are
                      implemented using advanced tools, equipments and software
                      for accurate analysis of the site. Being equipped with
                      state of art infrastructure facilities, we ensure quality
                      standards are met and no damage is done to any property.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="leftt row rightheight">
              <img src={infra} alt="" />
            </div>
          </div>

          {/* FOURTH box */}
          <div className="container flexSB aboutheight">
            <div className="right row rightheight">
              <Heading subtitle="" title="Quality" />
              <div className="items">
                <div className="item flexSB">
                  <div className="text">
                    <p>
                      Being associated with SR Associates Land Surveyors, be
                      assured of ethical business practices and transparency in
                      all dealings. Quality testing and inspection procedures
                      ensure our commitment for excellence and flawless
                      services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="leftt row rightheight">
              <img src={quality} alt="" />
            </div>
          </div>

          {/* fifth box */}

          <div className="container flexSB aboutheight">
            <div className="right row rightheight">
              <Heading subtitle="" title="Why Choos Us" />
              <div className="items">
                <div className="item flexSB">
                  <div className="text">
                    <p>
                      We have been in land surveying business since more than a
                      decade. The SR Associates Land Surveyors team is confident
                      of executing every land survey challenge diligently. There
                      are many factors at SR Associates Land Surveyors that will
                      differentiate us from others in the industry:
                    </p>
                    <div class="bullet-list">
                      <ul className="ullist">
                        <li>
                          Highly experienced professionals and consultants
                        </li>
                        <li>Vast industry experience</li>
                        <li>Sophisticated survey instruments</li>
                        <li>Customized solutions</li>
                        <li>Timely project execution with accuracy</li>
                        <li>Ethical business practices</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="leftt row rightheight">
              <img src={whywe} alt="" />
            </div>
          </div>
        </div>

        <div className="mobileRes">
          <Responsive_About/>
        </div>

        {/* <div className="mobileRes">

        </div> */}

      </section>
      {/* <AboutCard /> */}
    </div>
  );
};

export default About;
