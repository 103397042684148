import React from 'react'
import ServiceCard from './ServiceCard'
import Back from '../common/back/Back'
import "./service.css"
const Service = () => {
  return (
    <div className='service_margin'>
    <Back title='Services' />
      <section className='blog padding'>
        <div className='container grid2 servicetopheight'>
          <ServiceCard />
        </div>
      </section>
    </div>
  )
}

export default Service