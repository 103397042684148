import React from 'react'
 import {aboutSection} from "../../../dummydata" 
const Responsive_Card = () => {
  return (
    <>
      {aboutSection.map((val) => (
        <div className='items shadow responsive_Mobile_height'>
          <div className='img responsive_Card '>
            <img loading="lazy" src={val.cover}  alt='' />
          </div>
          <div className='text services_text'>
          
            <h1>{val.title}</h1>
            <p className='services_desc'>{val.desc}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default Responsive_Card