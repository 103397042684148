import React from 'react'

import {services} from"../../dummydata"
const ServiceCard = () => {
  return (
    <>
      {services.map((val) => (
        <div className='items shadow services_height'>
          <div className='img'>
            <img loading="lazy" src={val.cover} alt='' />
          </div>
          <div className='text services_text'>
           
            <h1 id='services_title'>{val.title}</h1>
            <p className='services_desc'>{val.desc}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default ServiceCard