import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import About from "./components/about/About"
import CourseHome from "./components/allcourses/CourseHome"
import Team from "./components/team/Team"
import Pricing from "./components/pricing/Pricing"
import Blog from "./components/blog/Blog"
import Contact from "./components/contact/Contact"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import Service from "./components/services/Service"
import icon from "../src/Assests/About1.jpg"
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions"
import privacypolicy from "./components/privacyPolicy/privacypolicy"
import ContactCard from "./components/contact/ContactCard"
function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/courses' component={CourseHome} />
          <Route exact path='/Gallary' component={Team} />
          <Route exact path='/Projects' component={Pricing} />
          <Route exact path='/Services' component={Service} />
          <Route exact path='/contact' component={ContactCard} />

          <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
          <Route exact path='/privacy-policy' component={privacypolicy} />
        </Switch>
        <Footer />
      </Router>

      

      

      
    </>
  )
}

export default App
