import React from "react"
import logo from "../../../Assests/Logo.png"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            {/* <h1>ACADEMIA</h1>
            <span>ONLINE EDUCATION & LEARNING</span> */}
            <img loading="lazy" src={logo} className="logosize" width={240}></img>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
