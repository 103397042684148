import React from 'react'
import Contact from './Contact'

import "../pricing/price.css"
import Back from '../common/back/Back'
const ContactCard = () => {
  return (
    <div className="project_margin">
      <Back title='Contact Us' />
      {/* <Testimonal/> */}
      <Contact/>
      {/* <Faq /> */}
    </div>
  )
}

export default ContactCard
