import React from 'react'
import Responsive_Card from './Responsive_Card'
import "./res.css"
import Back from '../../common/back/Back'
const Responsive_About = () => {
  return (
    <>
    {/* <Back title='Services' /> */}
      <section className='blog padding'>
        <div className='container grid2'>
          <Responsive_Card />
        </div>
      </section>
    </>
  )
}

export default Responsive_About