export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/laces/64/survey.png",
    title: "Property Boundary Identification",
    desc: " Surveys accurately define the boundaries of your property, helping to avoid legal disputes with neighbors.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/glyph-neue/64/survey--v1.png",
    title: "Land Development Planning",
    desc: "Surveys provide crucial information for planning and designing new developments or construction projects.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Property Value Assessment",
    desc: "Surveys can determine the value of a property, which is essential for buying, selling, or developing land.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "500+",
    title: "Surveys Completed",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "98%",
    title: "Average Accuracy Rate",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "95%",
    title: "Client Satisfaction Rate",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "12Years",
    title: "Team Experience",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Introducing to Software Engineering",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "HTML, CSS, and Javascript for Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const online = [
  {
    cover: "./images/home_Icon/Topologi_S.jpg",
    hoverCover: "./images/home_Icon/Topologi_S 1.png",
    courseName: "Topographic Survey",
    course: "Land/contour",
  },
  {
    cover: "./images/home_Icon/survey 1.png",
    hoverCover: "./images/home_Icon/survey 1 1.png",
    courseName: "Route Survey ",
    course: "Road/Railway",
  },
  {
    cover: "./images/home_Icon/studio 1.png",
    hoverCover: "./images/home_Icon/studio 1 1.png",
    courseName: "Command Survey",
    course: "Mapping",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "DGPS Survey",
    course: "GPS accuracy",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Earth Work Quantity Survey",
    course: "",
  },
  {
    cover: "./images/home_Icon/usa 1 (2).png",
    hoverCover: "./images/home_Icon/usa 1 (3).png",
    courseName: "Demarcation of Plot Layout",
    course: "",
  },
  {
    cover: "./images/home_Icon/building 1 (1).png",
    hoverCover: "./images/home_Icon/building 1.png",
    courseName: "Demarcation of Building Layout",
    course: "",
  },
  {
    cover: "./images/home_Icon/surveying 1.png",
    hoverCover: "./images/home_Icon/surveying 1 1.png",
    courseName: "Irrigation Survey",
    course: "Canal, Dam, Water line",
  },
  {
    cover: "./images/home_Icon/gas-pipe 1.png",
    hoverCover: "./images/home_Icon/gas-pipe 1 (1).png",
    courseName: "Gas Line Survey",
    course: "",
  },
  {
    cover: "./images/home_Icon/pipe 1.png",
    hoverCover: "./images/home_Icon/pipe 1 (1).png",
    courseName: "Pipe lines Survey",
    course: "",
  },
  {
    cover: "./images/home_Icon/surveying (1) 1.png",
    hoverCover: "./images/home_Icon/surveying (1) 1 1.png",
    courseName: "Boundary Survey",
    course: "",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Master Planning Support Survey",
    course: "",
  },
]
export const team = [
  {
    cover: "./images/team/myfile46.jpeg",
  },

 
  {
    cover: "./images/team/myfile20.jpg",
  },


  {
    cover: "./images/team/myfile34.jpeg",
  },
  {
    cover: "./images/team/myfile35.jpg",
  },
  {
    cover: "./images/team/myfile36.jpeg",
  },
  {
    cover: "./images/team/myfile37.jpg",
  },
  {
    cover: "./images/team/myfile38.jpeg",
  },
  {
    cover: "./images/team/myfile39.jpg",
  },
  {
    cover: "./images/team/myfile40.jpeg",
  },
  {
    cover: "./images/team/myfile41.jpg",
  },
  {
    cover: "./images/team/myfile42.jpeg",
  },
  {
    cover: "./images/team/myfile43.jpg",
  },
  {
    cover: "./images/team/myfile44.jpeg",
  },
  {
    cover: "./images/team/myfile45.jpg",
  },
  



  {
    cover: "./images/team/myfile47.jpg",
  },


  {
    cover: "./images/team/myfile1.jpeg",
  },
  {
    cover: "./images/team/myfile2.jpg",
  },
  {
    cover: "./images/team/myfile3.jpeg",
  },
  {
    cover: "./images/team/myfile4.jpg",
  },
  {
    cover: "./images/team/myfile5.jpeg",
  },
  {
    cover: "./images/team/myfile6.jpg",
  },
  {
    cover: "./images/team/myfile7.jpeg",
  },
  {
    cover: "./images/team/myfile8.jpg",
  },
  {
    cover: "./images/team/myfile9.jpeg",
  },
  {
    cover: "./images/team/myfile10.jpg",
  },
  {
    cover: "./images/team/myfile11.jpeg",
  },
  {
    cover: "./images/team/myfile12.jpg",
  },
  {
    cover: "./images/team/myfile13.jpeg",
  },
  {
    cover: "./images/team/myfile14.jpg",
  },
  {
    cover: "./images/team/myfile15.jpeg",
  },
  {
    cover: "./images/team/myfile16.jpg",
  },
  {
    cover: "./images/team/myfile17.jpeg",
  },
  {
    cover: "./images/team/myfile18.jpg",
  },
  {
    cover: "./images/team/myfile19.jpeg",
  },

  
  {
    cover: "./images/team/myfile21.jpeg",
  },
  {
    cover: "./images/team/myfile22.jpg",
  },
  {
    cover: "./images/team/myfile23.jpeg",
  },
  {
    cover: "./images/team/myfile24.jpg",
  },
  {
    cover: "./images/team/myfile25.jpeg",
  },
  {
    cover: "./images/team/myfile26.jpg",
  },
  {
    cover: "./images/team/myfile27.jpeg",
  },
  {
    cover: "./images/team/myfile28.jpg",
  },
  {
    cover: "./images/team/myfile29.jpeg",
  },
  {
    cover: "./images/team/myfile30.jpg",
  },

  {
    cover: "./images/team/myfile31.jpeg",
  },
  {
    cover: "./images/team/myfile32.jpg",
  },
  {
    cover: "./images/team/myfile33.jpeg",
  },
 

]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "Why You Need To Consult a Land Surveyor Before Buying a Home?",
    desc: "To identify property boundaries and avoid boundary disputes.To ensure there are no encroachments or easements affecting the property.To verify the property's legal description and physical features.     ",
  },
  {
    title: "What is a Survey & what is a Site Plan?",
    desc: "A survey is a methodical process of measuring and mapping a property's boundaries, features, and elevations.A site plan is a detailed drawing that shows the layout of a property, including buildings, roads, and utilities.",
  },
  {
    title: "Property Survey and its Importance",
    desc: "Property surveys are essential to determine boundaries, easements, and any encroachments before buying or developing land.",
  },
  {
    title: "Topographical Survey and Importance of Topographic Surveys",
    desc: "A topographical survey maps the contours of the land and is crucial for engineering and construction projects to understand the terrain.",
  },
  {
    title: "What is Contour Surveying? Methods, Maps and Uses of Contours in Surveying",
    desc: "Contour surveying is the process of determining the elevation points on a piece of land to create contour lines on a map, which helps visualize the land's shape and slope.",
  },
  {
    title: "What is a Land Survey and Types of Land Survey?",
    desc: "A land survey is the measurement and mapping of a piece of land's boundaries and features. Types include boundary surveys, ALTA surveys, and construction surveys.",
  },
  {
    title: "Building Survey or Structural Survey?",
    desc: "A building survey assesses the condition of a property's structure and fabric, while a structural survey focuses on the structural integrity and stability of a building.",
  },
  {
    title: "Flat Survey and Top 6 Benefits of Flat Survey",
    desc: "A flat survey, or level survey, measures the relative heights of points on a property. Benefits include accurate grading, drainage planning, and site preparation.",
  },
  {
    title: "What is Land Surveyors and Land Surveyor Services?",
    desc: "Land surveyors are professionals who conduct surveys and provide services such as boundary determination, topographic mapping, and construction layout.",
  },
  {
    title: "Land surveying and its importance",
    desc: "Land surveying is crucial for property development, construction, and infrastructure projects to ensure accurate measurements and legal compliance.",
  },
  {
    title: "Surveyor Meaning",
    desc: "A surveyor is a professional trained in surveying techniques to measure, map, and analyze land and property.",
  },
  {
    title: "Top 5 Advantages of Land Survey",
    desc: "Prevents boundary disputes. Ensures accurate property descriptions. Facilitates property development and construction. Helps with property valuation and sales. Ensures compliance with local regulations and zoning requirements.",
  },
  
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Layout Marking/Demarcation",
    desc: "Layout marking is a survey that accurately marks the boundaries of a construction site, ensuring compliance with approved plans and legal boundaries, preventing encroachments and disputes.",
    cover: "../images/land.png",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Engineering Land Survey",
    desc: "Our survey engineers use geometry, trigonometry, engineering, math, and physics concepts to measure land features accurately. They employ advanced technology like GPS, AutoCAD software, and digital photography to ensure quality in land development and construction projects",
    cover: "../images/map.png",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Earth Work Quantity Survey",
    desc: "Earthwork quantity survey calculates the volume of earth to be moved in a construction project, crucial for cost estimation and project planning. It ensures the right amount of material is used for land leveling, keeping the project on budget and schedule.",
    cover: "../images/earthwork.png",
  },
  // {
  //   id: 4,
  //   type: "admin",
  //   date: "JAN. 18, 2021",
  //   com: "3 COMMENTS ",
  //   title: "Layout Marking/Demarcation",
  //   desc: "Layout marking is a survey that accurately marks the boundaries of a construction site, ensuring compliance with approved plans and legal boundaries, preventing encroachments and disputes.",
  //   cover: "../images/land.png",
  // },
  // {
  //   id: 5,
  //   type: "user",
  //   date: "MAY. 15, 2022",
  //   com: "10 COMMENTS ",
  //   title: "Earth Work Quantity Survey",
  //   desc: "Earthwork quantity survey calculates the volume of earth to be moved in a construction project, crucial for cost estimation and project planning. It ensures the right amount of material is used for land leveling, keeping the project on budget and schedule.",
  //   cover: "../images/earthwork.png",
  // },
  
]
export const testimonal = [
  {
    id: 1,
    name: "M. S. ASSOCIATES",
    post: "Reliance Gas line Project",
    desc: "This project is new Gas line project. Stack out new line, Detail  topographic survey, Tree survey. And Fixing Alignment of Gas line. ",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "SAI ENTERPRISES",
    post: "Irrigation Project",
    desc: "Irrigation development work like contour survey, demarcation, as built survey ,Canal cross section work",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "SALASAR EXTERIOR",
    post: "Tata Cancer Hospital Vishakhapatnam",
    desc: "This project is Building project. Study drawing and layout marking,Grid line marking, contour survey etc.",
    cover: "./images/testo/t3.webp",
  },
]

export const services=[
  {
    id: 1,
    title: "Topographic Survey",
    desc: "A topographic survey gathers data about the elevation, contours, and features of a land area. It helps in creating detailed maps that show the shape and characteristics of the land's surface. These surveys are essential for various purposes such as urban planning.",
    cover: "../images/services/Topo.jpg",
  },
  
  // ../images/services/Topo.jpeg
  // ../images/services/Route.jpg
  // ../images/services/Demarcation.jpeg
  {
    id:2,
    title: "Route Survey",
    desc: "A route survey is conducted to determine the best path or route for infrastructure such as roads, railways, gas lines, or pipelines. It involves assessing the terrain, obstacles, and other factors to plan the most efficient and safe route. ",
    cover: "../images/services/Route.jpg",
  },
  {
    id:3 ,
    title: "Command Survey ",
    desc: "A command survey establishes precise reference points on a project site for accurate positioning in construction and mapping. It's crucial for ensuring all measurements and surveys on the site are consistent and accurate.",
    cover: "../images/services/acc.jpeg",
  },
  {
    id:4,
    title: "Irrigation Survey  ",
    desc: "An irrigation survey involves assessing land to design and implement irrigation systems efficiently. This includes planning canals, dams, water lines, and other infrastructure to optimize water distribution for agriculture or other purposes. ",
    cover: "../images/services/Dam.jpeg",
  },
  {
    id:5,
    title: "DGPS Survey",
    desc: "DGPS surveying enhances GPS accuracy by using a fixed receiver to correct GPS signals, ensuring precise location data. This method is vital for mapping, construction, and other applications requiring high-accuracy positioning.",
    cover: "../images/services/DGPS.jpeg",
  },
  {
    id:6,
    title: "Earthwork Quantity Survey ",
    desc: "Earthwork quantity survey calculates the volume of earth to be moved in a construction project, crucial for cost estimation and project planning. It ensures the right amount of material is used for land leveling, keeping the project on budget and schedule.",
    cover: "../images/services/Earth.jpg",
  },
  {
    id:7,
    title: "Demarcation of Plot and Building Layout",
    desc: "Demarcation of plots involves marking boundaries, while building layout determines the position of structures on the land. These surveys are essential for ensuring construction is done accurately and complies with regulations.",
    cover: "../images/services/Demarcation.jpg",
  }


]
export const aboutSection=[
  {
    id: 1,
    title: "SR ASSOCIATES LAND SURVEYORS – Prime Experts In Land Survey Of Land Survey",
    desc: "SR Associates Land Surveyors is a leading service provider in the domain of land surveying since 12 years. Our rich expertise and experience enables us to deliver competent consultancy services across real estate, construction and corporate sectors.",
    cover: "../images/mobo_about/About1.jpg",
  },

  {
    id:2,
    title: "Our Team",
    desc: "SR Associates Land Surveyors has a strong team of consultants, engineers, surveyors, analysts and marketing executives. The team is extremely reliable and capable of efficiently accomplishing the projects under a given deadline. We ensure our team undergoes through training programs at regular intervals to keep them updated about recent technological advancements.",
    cover: "../images/mobo_about/team.png",
  },
  {
    id:3 ,
    title: "Infrastructure",
    desc: "Our inspection procedures and survey operations are implemented using advanced tools, equipments and software for accurate analysis of the site. Being equipped with state of art infrastructure facilities, we ensure quality standards are met and no damage is done to any property.",
    cover: "../images/mobo_about/infraa.jpg",
  },
  {
    id:4,
    title: "Quality",
    desc: "Being associated with SR Associates Land Surveyors, be assured of ethical business practices and transparency in all dealings. Quality testing and inspection procedures ensure our commitment for excellence and flawless services.",
    cover: "../images/mobo_about/quality.png",
  },
  {
    id:5,
    title: "Why Choos Us",
    desc: `Highly experienced professionals ||
           Vast industry expertise ||
           Advanced survey instruments ||
           Customized solutions||
           Timely and accurate project execution ||
           Ethical business practices`,
    cover: "../images/services/DGPS.jpeg",
  },
  


]

export const project=[
  {
    id: 1,
    place:"-Belapur Mumbai",
    name: "M.S.ASSOCIATES",
    post: "Gail India , Reliance , Essar , Indian Oil Gas line Projects",
    desc: "This project is new Gas line project. Stack out new line, Detail  topographic survey, Tree survey. And Fixing Alignment of Gas line.The project took place from June 2013 to August 2023. ", 
  },

  {
    id: 2,
    place:"-Pune",
    name: "SAI ENTERPRISES",
    post: "Irrigation Project",
    desc: "Irrigation development work like contour survey, demarcation, as built survey ,Canal cross section work. The project took place from August 2013 to Till date.", 
  },
  // {
  //   id: 2,
  //   place:"Pune",
  //   name: "SAI ENTERPRISES",
  //   post: "Irrigation Project",
  //   desc: "Irrigation development work like contour survey, demarcation, as built survey ,Canal cross section work. The project took place from August 2013 to Till date. ",
    
  // },
  {
    id: 3,
    place:"-Vishakhapatnam",
    name: "SALASAR EXTERIOR",
    post: "Tata Cancer Hospital Vishakhapatnam",
    desc: "This project is Building project. Study drawing and layout marking,Grid line marking, contour survey etc. The project took place from October 2015 to October 2018.",
    
  },

  {
    id: 4,
    name: "LION ENGINEERING CONS.",
    place:"-Bhopal",
    post: "Topographic survey ",
    desc: "Established DGPS control point with GPS, Established Concrete pillar at every 250 meter interval, Traverse survey with TS & fixing X,Y,& Z value of TBM at 250 meter, Topographic survey-L-section 10M interval & X-section 50M interval by TS, Submission all drawing like Detail survey, L-sec & X sec all nallas. .The project took place from july 2016 to November 2016.",
    
  },
  {
    id: 5,
    name: "R.K.CHAVAN INFRASTRUCTURE PVT.LTD",
    place:"-Pune",
    post: "Topographic survey",
    desc: "Established DGPS control point with GPS, Established Concrete pillar at every 250 meter interval, Traverse survey with TS & fixing X,Y,& Z value of TBM at 250 meter, Topographic survey-L-section 10M interval & X-section 10M interval by TS,Submission all drawing like Detail survey, L-sec & X sec all nallas. .The project took place from May 2017 to July 2017.",
    
  },
  {
    id: 6,
    name:"DHRUV CONSULTANCY ",
    place:"-Belapur Mumbai",
    post: "Gujarat city property survey ",
    desc: "Detail Topographic survey at owner property's, Submission all drawing like Property sketch, Area, preparation of survey records Etc.(19 village survey at Surat & Ahmadabad Dist. Area.)  .The project took place from Dec 2016 to May 2018.",
    
  },
  {
    id: 7,
    name: "PAVE-TECH CONSULTANTS ",
    place:"-Shivajinagar Pune",
    post: " Topographic survey ",
    desc: " Established DGPS con trol point with GPS, Established Concrete pillar at every 200 meter interval, Traverse survey with TS & fixing X,Y,& Z value of TBM at 200 meter, Topographic survey-L-section 10M interval & X-section 10M interval up to Row by TS, Submission all drawing like Detail survey, L-sec & X sec.  .The project took place from  May 2018 tO June 2018",
    
  },
  {
    id: 8,
    name: "GIS APP CONSULTANCY SERVICES PVT.LTD",
    place:"-Bibwewadi Pune",
    post: "Water Line purpose levelling work survey",
    desc: "Total station survey for Road centrelines in proposed various zone.Taking Levels at every 30 meters, Established of Temporary Bench mark at every 500 meter interval and submission of all drawing like connecting all survey points, Zone wise generate Contours, L-section etc. .The project took place from Sep 2018 tO Dec 2018. ",
    
  },
  {
    id: 9,
    name: "GLOBAL WASTE MANAGEMENT CELL PVT.LTD",
    place:"-Jalgaon",
    post: "Topographic & Quantity Survey",
    desc: "Taking as built details of plot area, Taking OGL and EGL existing garbage dump area ground and preparation of as built drawing and cut-fill quantity calculation for existing dump area. The survey shall be carried out using Total Station.",
    
  },
  {
    id: 10,
    name: "Girish Infrastructure ",
    place:"",
    post: "Canol Topographic survey project.",
    desc: "This project our job is as a survey consultancy. As per client requirement this project detail topographic survey work ,Taking -L-section 30M interval & X-section 90 M interval by DGPS and TS, Submission all drawing like Detail survey, Draw all L-sec & X sec Canol profile. total 178 Km length of canol.",
    
  },
  {
    id: 11,
    name: "SACHIN KULKARNI & ASSOCIATES ",
    place:"Wardha & Akola",
    post: "DGPS Survey for MRSAC project",
    desc: "This project our job is as a survey consultancy. As per client requirement this project DGPS control point Observation at verious location, Primary Points- 8 Hrs Observation, Secondary Points- 3.5 Hrs Observation , Flying Point & Tertiary Point -30 min Observation , All data processing Baseline data and Submission client.",
    
  },
  {
    id: 12,
    name: "Nikhil Construction Group PVT LTD. ",
    place:"",
    post: " Nhavara To Chaufula Road Project",
    desc: "This project our job is as a survey consultancy. As per client requirement this project detail topographic survey work ,Taking -L-section 10M interval & X-section 90 M interval by DGPS and TS, Submission all drawing like Detail survey, Draw all L-sec & X sec Canol profile. total 24 Km length of road ",
    
  },
  {
    id: 13,
    name: "Sagar Bhore",
    place:"",
    post: "Mhaisal Irrigation Project  ",
    desc: "Irrigation development work like contour survey, demarcation, as built survey. ",
    
  },
  {
    id: 14,
    name: "AARAV UNMANNED SYSTEM",
    place:"Jharkhand State",
    post: " SAIL , HCL , NTPC , TISCON , OMC , SECL ",
    desc: " Making Ground Control Point For Drone Survey Work in various Mines In Orissa And Jharkhand State. ",
    
  },
  {
    id: 15,
    name: "CDF INFRACON PVT Ltd",
    place:"-Pune",
    post: "Service Road Project.",
    desc: "This project our job is as a survey consultancy. As per client requirement this project ,Taking X-section 20 M interval by DGPS Submission all drawing like Detail survey, Draw all L-sec & X sec Road profile. total 20 Km length of road",
    
  },
]