import React from "react"
import { team } from "../../dummydata"

const TeamCard = () => {
  return (
    <>
      {team.map((val) => (
        <div className='items shadow'>
          {/* <div className='img'> */}
            <img loading="lazy" src={val.cover} width={500} alt='' />
        </div>
      ))}
    </>
  )
}

export default TeamCard
