import React, { useState } from "react";
import Back from "../common/back/Back";
import "./contact.css";
import axios from "axios";

const Contact = () => {
   const [formData, setFormData] = useState( {
        email:"", subject:"",name:"",message:""
    })
    function changeHandler(event) {

      setFormData( (prevData) =>(
          {
              ...prevData,
              [event.target.name]:event.target.value
          }
      ) )
  }

  const handleSubmit = async (e) => { // Making the function asynchronous to use await
    e.preventDefault();
    try {
      
      const response = await axios.post('http://localhost:5000/mail',  { name: formData.name,email:formData.email,subject:formData.subject,message:formData.message });
      console.log(formData);
      console.log('Mail sent:', response.data);
      // Optionally, you can reset the form data after successful submission
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending mail:', error);
      // Handle error appropriately, e.g., show an error message to the user
    }
  };
  const map =
    'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3784.860454302661!2d74.585207!3d18.444644000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDI2JzQwLjciTiA3NMKwMzUnMDYuOCJF!5e0!3m2!1sen!2sin!4v1711983949754!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  return (
    <>
      {/* <Back title="Contact us" /> */}
      <section className="contacts padding">
        <div className="container shadow flexSB contact_height">
          <div className="contact_map left row">
            <iframe src={map}></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className="items contact_grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>Roshan Manzil, At/Post: Vetalnagar, Lingali Road, Taluka: Daund, Dist: Pune – 413801</p>
                <h4>Contact No: <span>+918552008921</span></h4>
                {/* <p> +918552008921</p> */}
              </div>
              <div className="box">
                <h4>Branch Office:</h4>
                <p>Shop no, 37 1st Floor, Sri Gururaj Seva Vaishnavi, Raghavendra Colony, KEB Road,Karnataka Bidar-585401</p>
                <h4>Contact No: <span>+917259248801</span></h4>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p> sraoffice.daund@gmail.com</p>
              </div>
              {/* <div className="box">
                <h4>PHONE:</h4>
                <p> +918552008921</p>
              </div> */}
            </div>

            {/* <form onSubmit={handleSubmit}>
              <div className="flexSB contact_from_flex">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                value={formData.subject}
                onChange={changeHandler}
              />
              <textarea
                cols="30"
                rows="10"
                placeholder="Create a message here..."
                name="message"
                value={formData.message}
                onChange={changeHandler}
              ></textarea>
              <button type="submit" className="primary-btn">
                SEND MESSAGE
              </button>
            </form> */}

            <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
