import React from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import { Typewriter } from "react-simple-typewriter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Hero = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/about');
  };
  return (
    <div className="top_cantainer">
      <section className="hero">
        <div className="container herocontainer">
          <div className="row home_height">
            <Heading
              subtitle="WELCOME TO SR ASSOCIATES LAND SURVEYORS"
              // title="Your Land, Our Expertise"
            />
            <h1
              style={{
                // paddingTop: "5rem",
                margin: "auto 0",
                fontWeight: "normal",
              }}
            >
              <span className="heddingTitle">Your Land, Our </span>
              <span className="heddingTitle">
                {/* Style will be inherited from the parent element */}
                <Typewriter
                  words={[
                    "Knowledge",
                    "Proficiency",
                    "Experience",
                    "Expertise!",
                  ]}
                  loop={5}
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h1>

            <p id="homePara">
              For 12 years, we've been successfully surveying <br></br>projects
              in and around India.
            </p>
          </div>
         
        </div>
        
      </section>

      <div className="aboutbutton">
      {/* Button with onClick event */}
      <button onClick={handleClick} className="primary-btn">About Us</button>
    </div>
      
    </div>
  );
};

export default Hero;
