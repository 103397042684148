import React, { useState } from "react";
import { Link } from "react-router-dom";
import Head from "./Head";
import "./header.css";
import { CiPhone } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import mobile from "../../../Assests/Mobile.png"

const Header = () => {
  const [click, setClick] = useState(false);

  
  return (
    <>
      <div className="info_Nav">
        <p>
          <a href="tel:+918552008921" className="info_bar">
            <CiPhone />
            <h3 style={{fontSize:"16px"}}>+918552008921</h3>
          </a>
        </p>
        <p>
          <a href="mailto:sraoffice.daund@gmail.com" className="info_bar">
            <MdOutlineEmail />
            <h4 className="info_bar">sraoffice.daund@gmail.com</h4>
          </a>
        </p>

        <h3></h3>
      </div>
      <Head />
      <header className="mobile_Navbar">
        <nav className="flexSB">
          <ul
            className={click ? "mobile-nav" : "flexSB "}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/Services">Services</Link>
            </li>

            <li>
              <Link to="/Projects">Projects</Link>
              {/* Project */}
            </li>

            <li>
              <Link to="/Gallary">Gallary</Link>
            </li>

            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
              <Link to='/courses'>All Courses</Link>
            </li> */}
          </ul>
          <div className="start">
            <div className="button"></div>
          </div>
          <div className="mobile_Icon">
            <img loading="lazy" src={mobile} width={180}></img>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
        {/* WhatAPP */}
        {/* whtsapp icon */}
        <div>
          <a
            href="https://wa.me/+918552008921?text=Hi Sohel ,I would like to know about your Survey Services"
            target="_blank"
            class="btn-whatsapp-pulse btn-whatsapp-pulse-border"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
        </div>
      </header>
    </>
  );
};

export default Header;
