import React from "react"
import "../blog/blog.css"
import { blog } from "../../dummydata"
import Heading from "../common/heading/Heading"

// copy code of blog => blogCard

const Hblog = () => {
  return (
    <>
      <section className='blog'>
        <div className='container'>
          <Heading subtitle='' title='Experienced Surveying Services' />
          <div className='grid2'>
            {blog.slice(0, 3).map((val) => (
              <div className='items shadow blog_items'>
                <div className='img'>
                  <img loading="lazy" src={val.cover} className=" imgheight" alt='' />
                </div>
                <div className='text'>
                 
                  <h1 id="home_blog_head">{val.title}</h1>
                  <p className="home_blog_desc">{val.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Hblog
